import * as Auth from "./auth";

export const simple = (...args) => fetch(...args).then((res) => res.json());

const urlRegex =
  /\s*(GET|HEAD|POST|PUT|DELETE|TRACE|OPTIONS|CONNECT|PATCH)\s*(.*)/;

const language = "zh-TW";

const createFetcher =
  (f) =>
  (key, options = {}) => {
    const result = urlRegex.exec(key);
    if (!result) return f(key, options);
    const [, method, url] = result;
    return f(url, { method, ...options });
  };

const complex = createFetcher((url, options = {}) => {
  const go = () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) return Promise.reject(new Error("user not found"));
      if (!user.access)
        return Promise.reject(new Error("access token not found"));
      return fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: "Bearer " + user.access,
        },
      });
    } catch (err) {
      return Promise.reject(err);
    }
  };

  return go().then((res) => {
    if (res.ok) {
      return res.status === 204 ? undefined : res;
    } else {
      if (res.status === 403) {
        // refresh token & retry once
        return Auth.refresh()
          .then(go)
          .then((res) => {
            if (res.ok && res.status === 204) return;
            if (res.ok) return res;
          });
      } else {
        // throw HTTP errors
        return res.json().then((obj) => {
          if (obj.err_message !== undefined) {
            throw new Error(obj.err_message);
          } else {
            throw new Error(obj.detail);
          }
        });
      }
    }
  });
});

export const withToken = (url, options = {}) =>
  complex(url, options).then((res) => (res ? res.json() : undefined));

export const getCSV = (url) =>
  complex(url, {
    headers: {
      "Accept-Language": language,
      "Content-Type": "text/csv",
    },
  }).then((res) => (res ? res.text() : undefined));

export const post = (url, data) =>
  withToken(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

export const postWithFormData = (url, formData) =>
  withToken(url, {
    method: "POST",
    body: formData,
    // remove header so brower can send FormData with a correct boundary
    // see: https://stackoverflow.com/questions/39280438/fetch-missing-boundary-in-multipart-form-data-post
  });

export const patchWithFormData = (url, formData) =>
  withToken(url, {
    method: "PATCH",
    body: formData,
  });

export const put = (url, data) =>
  withToken(url, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

export const deleteWithToken = (url) =>
  withToken(url, {
    method: "DELETE",
  });

function createCustomer(id) {
  return {
    id: `${id}`,
    datetime: "20201212 12:12",
    "puerpera.name": `陳美麗 ${id}`,
    contactor: `王曉明 ${id}`,
    "puerpera.mobile_phone": "0900123456",
    "puerpera.tw_identity": "A234567890",
    status: "產婦",
  };
}

const customers = Array.from(Array(100).keys()).map(createCustomer);

export const mockedCustomers = (query) => {
  const { page = 1, pageSize = 10, q = "" } = query;
  const begin = (page - 1) * pageSize;
  const end = begin + pageSize;
  const result = customers.filter((c) => c["puerpera.name"].includes(q));
  const sliced = result.slice(begin, end);
  return Promise.resolve({
    page: page,
    pageSize: pageSize,
    total: result.length,
    data: sliced,
  });
};
