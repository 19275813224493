import { Link, useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Grid,
  Row,
  Tile,
} from "carbon-components-react";
import { RequestQuote16, Undo16 } from "@carbon/icons-react";
import { HousingViewer } from "../../components/Viewer";

import useSWR from "swr";

import { URL, Fetcher as F } from "../../api";

export const HousingInfo = () => {
  const { hid } = useParams();

  const expand =
    "events,foodvendor,bookRoom.puerpera,puerperaNote," +
    "bookRoom.contract,bookRoom.first_brs,bookRoom.latest_brs.room.center,";

  const housingURL = `${URL.housing}${hid}/?expand=${expand}`;
  const { data } = useSWR([housingURL], F.withToken);

  const isLoading = !data;

  return (
    <Content className="housing-info">
      <Breadcrumb>
        <BreadcrumbItem href="/housing">住房管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          瀏覽
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>瀏覽住房狀態</Tile>

      <Grid>
        <Column>
          <HousingViewer loading={isLoading} data={data} />

          <Row>
            <Column sm={{ span: 1, offset: 1 }}>
              <Button
                as={Link}
                to={`/housing/${hid}/edit`}
                kind="primary"
                size="small"
                renderIcon={RequestQuote16}
              >
                編輯
              </Button>
            </Column>
            <Column>
              <Button
                as={Link}
                to="/housing/"
                kind="primary"
                size="small"
                renderIcon={Undo16}
              >
                返回住房管理
              </Button>
            </Column>
          </Row>
        </Column>
        <Column sm={1} />
      </Grid>
    </Content>
  );
};
